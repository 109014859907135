<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Bevestiging van je aanvraag" />
    <h1>Bevestiging van je aanvraag</h1>
    <hr />
    <h3>Bedankt voor je aanvraag!</h3>
    <p>
      We hebben je aanvraag goed ontvangen en gaan hier nu mee aan de slag:
    </p>
    <ul>
      <li>De verwerking van je aanvraag kan enkele dagen duren.</li>
      <li>
        Je aanvraag is pas definitief als je een bevestigingsmail hebt gekregen.
      </li>
      <li>
        Getoonde prijzen zijn indicatief. De definitieve prijs wordt vermeld bij
        goedkeuring van je aanvraag
      </li>
      <li>
        Je kan de status van de aanvraag opvolgen in je persoonlijk
        <router-link to="/je-account">dashboard</router-link>.
      </li>
    </ul>
    <p>
      Heb je vragen over je reservering? Neem dan contact op met
      <a href="mailto:uitleendienst@vlaamsbrabant.be"
        >uitleendienst@vlaamsbrabant.be</a
      >
    </p>
    <h3>Overzicht van je aanvraag</h3>
    <b-row>
      <b-col md="12" lg="6">
        <b-card>
          <div>
            <strong>Afhaallocatie</strong>
            <p>{{ reservation.WarehouseID }}</p>
          </div>
          <div>
            <strong>Afhaaldatum</strong>
            <p>
              {{ formatDate(reservation.DateTimeBusinessStart) }},
              {{ reservation.BusinessHourStart }}
            </p>
          </div>
          <div>
            <strong>Terugbrengdatum</strong>
            <p>
              {{ formatDate(reservation.DateTimeBusinessEnd) }},
              {{ reservation.BusinessHourEnd }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col md="12" lg="6">
        <b-table :items="filteredItems" :fields="fields" class="mt-3 mt-md-0">
          <template v-slot:cell(DbTotalExcVAT)="data">
            <div class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.TotalExcVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <div class="prices">
          <b-row>
            <b-col cols="9" class="text-right cart-total-price">
              <p>
                <strong>Subtotaal:</strong>
              </p>
              <p v-if="adminCosts">
                <strong>Administratiekosten:</strong>
              </p>

              <p>
                <strong>Totaalbedrag:</strong>
              </p>
            </b-col>
            <b-col cols="3" class="text-right price-values">
              <p>
                &euro;
                {{
                  totalIncVat
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </p>
              <p v-if="adminCosts">&euro; 10,00</p>
              <p>
                &euro;
                {{
                  reservation.TotalIncVAT.toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12">
        <h3>Nog producten aanvragen?</h3>
        <router-link
          class="btn btn-primary"
          :to="{ name: 'index' }"
          variant="primary"
          >Nieuwe aanvraag plaatsen</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
import dayjs from 'dayjs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      fields: [
        {
          key: 'ItemDescription',
          label: 'Item'
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'Amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'DbTotalExcVAT',
          label: 'Totaal',
          class: 'text-right'
        }
      ]
    }
  },
  computed: {
    adminCosts: function() {
      if (
        this.reservation.ReservationItems.find(
          reservationItem => reservationItem.ItemID === 'ADMIN'
        )
      )
        return true
      return false
    },
    filteredItems: function() {
      if (this.reservation.ReservationItems) {
        return this.reservation.ReservationItems.filter(function(obj) {
          return obj.ItemDescription !== 'Administratiekosten'
        })
      }
      return null
    },
    totalIncVat: function() {
      if (this.adminCosts) return this.reservation.TotalIncVAT - 10.0
      return this.reservation.TotalIncVAT
    },
    reservation: function() {
      let reservationData = this.$store.getters.reservation.data

      reservationData.ReservationItems = reservationData.ReservationItems.filter(
        function(obj) {
          return obj.Description !== 'Administratiekosten'
        }
      )
      return reservationData
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.prices {
  padding-right: 10px;
}
h1 {
  font-size: 50px;
}
h3 {
  font-size: 35px;
}
.card,
p,
.table {
  font-size: 18px;
}
.card {
}
a {
  text-decoration: none;
}
.price-values {
  p {
    padding-bottom: 3px !important;
  }
}
</style>
